<script>
import GizmoComponentMixin from "~/mixins/GizmoComponentMixin.js";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

export default defineNuxtComponent({
	name: "GizmoButton",
	mixins: [GizmoComponentMixin],

	computed: {
		buttons() {
			return (
				this.content.buttons?.map((button) => {
					return {
						...button,
						link: LinkViewModel(button.link),
					};
				}) || []
			);
		},
	},
});
</script>

<template>
	<div class="buttons">
		<div
			v-for="(button, idx) in buttons"
			:key="idx"
		>
			<TnLink
				v-if="button.type === 'link'"
				:dark="dark"
				:to="button.link.to || button.link.href"
				arrowRight
				responsive
				>{{ button.link.text }}</TnLink
			>
			<TnButton
				v-else
				v-bind="button.link"
				:icon-left="button.icon?.left"
				:icon-right="button.icon?.right"
				:primary="button.type === 'primary'"
				:secondary="button.type === 'secondary'"
				:dark="dark"
				responsive
				class="button"
			>
			</TnButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: $spacing-m;
	align-items: center;
	@include breakpoint(mobile) {
		gap: $spacing-s;
	}
}
</style>
